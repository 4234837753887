exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-2020-index-js": () => import("./../../../src/pages/2020/index.js" /* webpackChunkName: "component---src-pages-2020-index-js" */),
  "component---src-pages-2020-schedule-js": () => import("./../../../src/pages/2020/schedule.js" /* webpackChunkName: "component---src-pages-2020-schedule-js" */),
  "component---src-pages-2020-scholarship-js": () => import("./../../../src/pages/2020/scholarship.js" /* webpackChunkName: "component---src-pages-2020-scholarship-js" */),
  "component---src-pages-2020-speakers-js": () => import("./../../../src/pages/2020/speakers.js" /* webpackChunkName: "component---src-pages-2020-speakers-js" */),
  "component---src-pages-2020-sustain-africa-js": () => import("./../../../src/pages/2020/sustain-africa.js" /* webpackChunkName: "component---src-pages-2020-sustain-africa-js" */),
  "component---src-pages-2020-tailwind-config-demo-js": () => import("./../../../src/pages/2020/tailwind-config-demo.js" /* webpackChunkName: "component---src-pages-2020-tailwind-config-demo-js" */),
  "component---src-pages-2020-travel-js": () => import("./../../../src/pages/2020/travel.js" /* webpackChunkName: "component---src-pages-2020-travel-js" */),
  "component---src-pages-2022-gallery-js": () => import("./../../../src/pages/2022/gallery.js" /* webpackChunkName: "component---src-pages-2022-gallery-js" */),
  "component---src-pages-2022-index-js": () => import("./../../../src/pages/2022/index.js" /* webpackChunkName: "component---src-pages-2022-index-js" */),
  "component---src-pages-2022-schedule-js": () => import("./../../../src/pages/2022/schedule.js" /* webpackChunkName: "component---src-pages-2022-schedule-js" */),
  "component---src-pages-2022-speakers-js": () => import("./../../../src/pages/2022/speakers.js" /* webpackChunkName: "component---src-pages-2022-speakers-js" */),
  "component---src-pages-2022-sustain-africa-js": () => import("./../../../src/pages/2022/sustain-africa.js" /* webpackChunkName: "component---src-pages-2022-sustain-africa-js" */),
  "component---src-pages-2022-travel-js": () => import("./../../../src/pages/2022/travel.js" /* webpackChunkName: "component---src-pages-2022-travel-js" */),
  "component---src-pages-2023-gallery-js": () => import("./../../../src/pages/2023/gallery.js" /* webpackChunkName: "component---src-pages-2023-gallery-js" */),
  "component---src-pages-2023-index-js": () => import("./../../../src/pages/2023/index.js" /* webpackChunkName: "component---src-pages-2023-index-js" */),
  "component---src-pages-2023-schedule-js": () => import("./../../../src/pages/2023/schedule.js" /* webpackChunkName: "component---src-pages-2023-schedule-js" */),
  "component---src-pages-2023-speakers-js": () => import("./../../../src/pages/2023/speakers.js" /* webpackChunkName: "component---src-pages-2023-speakers-js" */),
  "component---src-pages-2023-sustain-africa-js": () => import("./../../../src/pages/2023/sustain-africa.js" /* webpackChunkName: "component---src-pages-2023-sustain-africa-js" */),
  "component---src-pages-2023-travel-js": () => import("./../../../src/pages/2023/travel.js" /* webpackChunkName: "component---src-pages-2023-travel-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-past-events-js": () => import("./../../../src/pages/past-events.js" /* webpackChunkName: "component---src-pages-past-events-js" */),
  "component---src-pages-schedule-js": () => import("./../../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-sustain-africa-js": () => import("./../../../src/pages/sustain-africa.js" /* webpackChunkName: "component---src-pages-sustain-africa-js" */),
  "component---src-pages-travel-js": () => import("./../../../src/pages/travel.js" /* webpackChunkName: "component---src-pages-travel-js" */)
}

